<template>
    <div class="bullets">
        <template v-for="(bullet, index) in bullets">
            <bullet
                :key="bullet.id"
                v-view="(event) => focusedBullet(event, bullet.id)"
                :bullet="bullet"
                :bullets-display-type="bulletsDisplayType"
                :bullet-index="index"
                :focused-id="focusedBulletId"
            />
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import Vue from "vue";
import checkView from "vue-check-view";
Vue.use(checkView);

export default {
    name: "Bullets",
    components: {
        Bullet: () => import(/* webpackChunkName: "bullet" */ "./bullet")
    },
    props: {
        memoId: {
            type: Number,
            required: true
        },
        bullets: {
            type: Array,
            default() {
                return []
            }
        },
        bulletsDisplayType: {
            type: String,
            default: "bullet"
        }
    },
    data() {
        return {
            focusedBulletId: null
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        })
    },
    watch: {
        focusedBulletId(newVal, oldVal) {
            if (newVal != oldVal) {
                this.trackReadBullet();
            }
        }
    },
    methods: {
        focusedBullet(event, bulletId) {
            const elementCenterPosition = event.percentCenter.toFixed(1);
            const middleOFViewPort = 0.5;
            if (elementCenterPosition == middleOFViewPort) {
                this.focusedBulletId = bulletId;
            }
        },
        trackReadBullet() {
            if (!this.isLoggedIn) {
                return;
            }

            const bullet = this.bullets.find(bullet => bullet.id == this.focusedBulletId);
            if (!bullet.is_read) {
                axios.post(`/feeds/${this.memoId}/bullets/${this.focusedBulletId}/read`).then(({ data }) => {
                    this.$emit("read-bullet", data);
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.bullets {
    font-size: 13px;
}
</style>
